import React from "react";
import { Button, Stack } from "@budgeinc/budge-ui-core";
import ButtonDropdown, { TButtonDropdownProps } from "./ButtonDropdown";

type OwnProps = Omit<TButtonDropdownProps, "onPress" | "dropdownOverlay"> & {
  onPress(includePii: boolean): void;
};

const ExportAsCsvButton = ({ onPress, size = "sm", ...props }: OwnProps) => (
  <ButtonDropdown
    title="Export as CSV"
    color="primary"
    variant="gradient"
    size={size}
    {...props}
    onPress={() => onPress(false)}
    dropdownOverlay={toggle => (
      <Stack m="md">
        <Button
          color="primary"
          variant="gradient"
          title="With PII"
          size="sm"
          onPress={() => {
            toggle();
            onPress(true);
          }}
        />
        <Button
          color="primary"
          variant="gradient"
          title="Without PII"
          size="sm"
          onPress={() => {
            toggle();
            onPress(false);
          }}
        />
      </Stack>
    )}
  />
);

export default ExportAsCsvButton;
