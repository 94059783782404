/* eslint-disable react/no-unstable-nested-components */
import { ProgramEnrollmentOutput, ProgramEnrollmentOutputStatusEnum } from "@budgeinc/budge-api";
import {
  InfiniteScrollTable,
  Description,
  Divider,
  formatEmployeeName,
  Text,
  EMPTY_VALUE_PLACEHOLDER,
  PaymentStatusProgress,
  Box,
} from "@budgeinc/budge-ui-core";
import { accountEntityRoute } from "common/routes";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { Link } from "components/Link";
import { hasFilters } from "components/ListFilterBar/utils";
import { useProgramEnrollmentFilterContext } from "components/Table/ProgramEnrollmentsTable/filters/context";
import DateDisplay from "components/DateDisplay";
import { getPerTransactionAmountDisplay } from "utils/transactions";
import React, { useCallback, useMemo } from "react";
import { getProgramEnrollmentsColumns } from "./columns";
import { TInfiniteScrollDataTableProps } from "../types";
import { InfiniteScrollDataTableContextType } from "../tableContext";
import { ProgramEnrollmentDataTableContext } from "./ProgramEnrollmentDataTableContext";

type OwnProps = TInfiniteScrollDataTableProps<ProgramEnrollmentOutput> & {
  showEmployee?: boolean;
  updateData: (data: ProgramEnrollmentOutput[]) => void;
};

const ProgramEnrollmentsTable = ({
  emptyLocal,
  showEmployee = false,
  updateData,
  requestState,
  ...props
}: OwnProps) => {
  const {
    state: { employer },
  } = useEmployerContext();
  const filters = useProgramEnrollmentFilterContext();

  const updateRecord = useCallback(
    (updatedRecord: ProgramEnrollmentOutput) => {
      updateData(requestState.data.map(record => (record.id === updatedRecord.id ? updatedRecord : record)));
    },
    [requestState]
  );

  const memoedDataTableContext = useMemo<InfiniteScrollDataTableContextType<ProgramEnrollmentOutput>>(
    () => ({
      updateRecord,
    }),
    [updateData, updateRecord]
  );

  return (
    <ProgramEnrollmentDataTableContext.Provider value={memoedDataTableContext}>
      <InfiniteScrollTable
        {...props}
        requestState={requestState}
        columns={getProgramEnrollmentsColumns({ showEmployee, employerId: employer?.id! })}
        local={{
          empty: {
            m: 24,
            title: "No program enrollments found",
            description: hasFilters(filters)
              ? `No program enrollment match these filters. Edit or clear all filters.`
              : undefined,
            ...emptyLocal,
          },
          noMoreItems: "No more program enrollments to load",
        }}
        contentContainerStyle={{ marginBottom: 16 }}
        expandable={({
          id,
          employee,
          program,
          financialAccountId,
          updatedAt,
          createdAt,
          perTransactionFeeModel,
          perTransactionFeeAmount,
          debtPaymentPlanId,
          isLastOneTimeForAccount,
          isForMinimumPayment,
          noTransactionBeforeDate,
          status,
          latestTransactionDetails,
          onePaymentPlanId,
          onePaymentSource,
          onePaymentUserLastUpdatedAt,
          onePaymentStatementReceivedAt,
          onePaymentCutoffReachedAt,
          onePaymentEmailSentAt,
        }) => (
          <>
            <Description>
              <Description.Item label="ID">{id}</Description.Item>
              <>
                {showEmployee && (
                  <Description.Item label="Customer">{formatEmployeeName({ employee, withId: true })}</Description.Item>
                )}
              </>
              <Description.Item label="Financial Account ID">
                <Text>
                  <Link to={accountEntityRoute(employee.employerId, employee.id, financialAccountId)}>
                    {financialAccountId}
                  </Link>
                </Text>
              </Description.Item>
              <Description.Item label="Debt Plan ID">
                <Text>{(debtPaymentPlanId as any) || EMPTY_VALUE_PLACEHOLDER}</Text>
              </Description.Item>
            </Description>
            <Divider />
            <Description>
              <Description.Item label="Fund Source Type">{program.fundSourceType}</Description.Item>
              <Description.Item label="Program Type">{program.type}</Description.Item>
              <Description.Item label="Is Minimum Payment">{isForMinimumPayment ? "true" : "false"}</Description.Item>
              <Description.Item label="Is Last One time">{isLastOneTimeForAccount ? "true" : "false"}</Description.Item>
              <Description.Item label="No Transaction Before Date">
                {noTransactionBeforeDate ? (
                  <DateDisplay value={noTransactionBeforeDate} showTime />
                ) : (
                  EMPTY_VALUE_PLACEHOLDER
                )}
              </Description.Item>
            </Description>
            <Divider />
            <Description>
              <Description.Item label="OnePay Plan ID" alignItems="center">
                <Text>{(onePaymentPlanId as any) || EMPTY_VALUE_PLACEHOLDER}</Text>
              </Description.Item>
              <Description.Item label="OnePay Plan Source" alignItems="center">
                {onePaymentSource || EMPTY_VALUE_PLACEHOLDER}
              </Description.Item>
              <Description.Item label="OnePay Plan User Last Updated At" alignItems="center">
                {onePaymentUserLastUpdatedAt ? (
                  <DateDisplay value={onePaymentUserLastUpdatedAt} />
                ) : (
                  <Text>{EMPTY_VALUE_PLACEHOLDER}</Text>
                )}
              </Description.Item>
              <Description.Item label="OnePay Plan Statement Received At" alignItems="center">
                {onePaymentStatementReceivedAt ? (
                  <DateDisplay value={onePaymentStatementReceivedAt} />
                ) : (
                  <Text>{EMPTY_VALUE_PLACEHOLDER}</Text>
                )}
              </Description.Item>
              <Description.Item label="OnePay Plan Cutoff Reached At" alignItems="center">
                {onePaymentCutoffReachedAt ? (
                  <DateDisplay value={onePaymentCutoffReachedAt} />
                ) : (
                  <Text>{EMPTY_VALUE_PLACEHOLDER}</Text>
                )}
              </Description.Item>
              <Description.Item label="OnePay Plan Email Sent At" alignItems="center">
                {onePaymentEmailSentAt ? (
                  <DateDisplay value={onePaymentEmailSentAt} />
                ) : (
                  <Text>{EMPTY_VALUE_PLACEHOLDER}</Text>
                )}
              </Description.Item>
            </Description>
            <Divider />
            <Description>
              <Description.Item label="Transaction Fee Model" alignItems="center">
                {perTransactionFeeModel}
              </Description.Item>
              <Description.Item label="Transaction Fee Amount" alignItems="center">
                {getPerTransactionAmountDisplay(perTransactionFeeModel, perTransactionFeeAmount)}
              </Description.Item>
            </Description>
            <Divider />
            <Description>
              <Description.Item label="Updated At">
                <DateDisplay numberOfLines={1} value={updatedAt} showTime />
              </Description.Item>
              <Description.Item label="Created At">
                <DateDisplay numberOfLines={1} value={createdAt} showTime />
              </Description.Item>
            </Description>
            {status === ProgramEnrollmentOutputStatusEnum.Enabled && (
              <>
                <Divider />
                <Box maw={500}>
                  <PaymentStatusProgress transactionDetails={latestTransactionDetails} />
                </Box>
              </>
            )}
          </>
        )}
      />
    </ProgramEnrollmentDataTableContext.Provider>
  );
};

export default ProgramEnrollmentsTable;
