import { ExportRequestOutput } from "@budgeinc/budge-api";
import { toast } from "@budgeinc/budge-ui-core";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { backofficeApi } from "api/BudgeApi";
import { download, generateDownloadFileName } from "utils/download";

export const backoffice = createQueryKeys("backoffice", {
  fetchExportRequest: (request: ExportRequestOutput) => ({
    queryKey: [request.id, request.updatedAt],
    queryFn: async () => {
      const response = await backofficeApi.getExportRequestFile(request.id);

      toast.success({
        title: "Success",
        message: "File downloaded successfully",
      });

      download(response.data, generateDownloadFileName(request.type.toLowerCase(), "csv"), "csv");

      return response.data;
    },
  }),
});
