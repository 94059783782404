import React, { ReactNode } from "react";
import {
  ActionIcon,
  Button,
  ChevronDownIcon,
  Dropdown,
  Stack,
  TButtonProps,
  TSize,
  useButtonStyles,
  useDisclosure,
  useTheme,
} from "@budgeinc/budge-ui-core";

export type TButtonDropdownProps = Omit<TButtonProps, "size"> & {
  size?: TSize;
  dropdownOverlay(toggleDropdown: () => void): ReactNode;
};

const ButtonDropdown = ({ dropdownOverlay, ...props }: TButtonDropdownProps) => {
  const theme = useTheme();
  const disclosure = useDisclosure();

  const { contentColor } = useButtonStyles({
    disabled: props.disabled || false,
    variant: props.variant || "default",
    titleColor: props.titleTextProps?.color,
    color: props.color || "dark",
    hoverColor: props.hoverColor,
    size: props.size || "md",
    fullWidth: props.fullWidth || false,
    isHovered: false,
    withLeftIcon: false,
    withRightIcon: false,
  });

  return (
    <Stack.Horizontal spacing={0}>
      <Button
        sx={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderRightColor: props.variant === "outline" ? undefined : theme.fn.fade(contentColor, 0.75),
        }}
        pr="sm"
        {...props}
      />
      <Dropdown
        isOpen={disclosure.isOpen}
        onOpenChange={disclosure.toggle}
        placement="bottom right"
        anchor={
          <ActionIcon
            icon={ChevronDownIcon}
            size={props.size}
            variant={props.variant}
            color={props.color}
            iconSize="sm"
            onPress={disclosure.toggle}
            sx={{
              borderTopRightRadius: 999,
              borderBottomRightRadius: 999,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          />
        }
        overlay={dropdownOverlay(disclosure.toggle)}
      />
    </Stack.Horizontal>
  );
};

export default ButtonDropdown;
