export type TListFilterState<T = any> = T;

export type TListFilterAction<T = any> = { type: "updateFilters"; data: T } | { type: "resetFilters" };

export const createListFilterReducer =
  <T>(initialState: TListFilterState<T>) =>
  (state: TListFilterState<T>, action: TListFilterAction<T>): TListFilterState<T> => {
    switch (action.type) {
      case "resetFilters":
        return {
          ...state,
          ...initialState,
        };
      case "updateFilters":
        return {
          ...state,
          ...action.data,
        };
    }
  };
