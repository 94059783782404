import { NotificationInput, NotificationOutput } from "@budgeinc/budge-api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { usersApi } from "api/BudgeApi";
import { queries } from "api/queries";

export const useFetchNotifications = () =>
  useQuery({
    ...queries.user.notifications,
    staleTime: 30 * 60 * 1000,
  });

export const useUpdateNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: { notificationId: string; updateInput: NotificationInput }) => {
      const response = await usersApi.updateNotification(params.notificationId, params.updateInput);
      return response.data;
    },
    onSuccess: updatedNotification => {
      const fetchNotifQueryKey = queries.user.notifications.queryKey;

      queryClient.setQueryData(
        fetchNotifQueryKey,
        queryClient.getQueryData<NotificationOutput[]>(fetchNotifQueryKey)?.map(notification => {
          if (notification.id === updatedNotification.id) return updatedNotification;
          return notification;
        })
      );
    },
  });
};
