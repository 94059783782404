import React from "react";

import { TListFilterAction, TListFilterState } from "./reducer";

export interface TListFilterContextType<T = any> {
  dispatch: React.Dispatch<TListFilterAction<T>>;
  state: TListFilterState<T>;
  initialFilters?: T;
}

export const createListFilterContext = <T>(initialState: TListFilterState<T>) =>
  React.createContext<TListFilterContextType<T>>({
    dispatch: () => {},
    state: initialState,
    initialFilters: initialState,
  });
