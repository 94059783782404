import { EmployeeOutput } from "@budgeinc/budge-api";
import { useQuery } from "@tanstack/react-query";
import { queries } from "api/queries";

export const useFetchSubscription = (employee: EmployeeOutput | null) =>
  useQuery({
    ...queries.clients.subscription(employee),
    staleTime: 5 * 60 * 1000,
  });

export const useFetchDebtPlanStats = (employee: EmployeeOutput | null) =>
  useQuery({
    ...queries.clients.debtPlanStats(employee),
    staleTime: 5 * 60 * 1000,
  });
